* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #000;
}

ul, li {
    list-style: none;
}

canvas#neuro {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; /* Ensure canvas covers full height */
    pointer-events: none;
    opacity: .5;
    z-index: -1;
}

.App {
    z-index: 2;
}

.button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-weight: 700;
    justify-content: center;
    align-items: center;
    padding: .4rem 1rem;
    border-radius: 5rem;
    background: transparent;
    color: white;
    border: 2px solid rgba(255, 255, 255, .2);
    min-width: 100px;
    text-decoration: none;

  text-shadow: 3px 3px 20px #000;
}


.button-primary {
  background-image: radial-gradient(circle 590px at 8.2% 13.8%, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 90%);
    color: #fff;
    transition: all .5s ease;
    font-weight: 500;
}

.button-primary:hover {
  background-image: radial-gradient(circle 590px at 8.2% 13.8%, #ccc 0%, #fff 90%);
    color: #000;
}

.button-secondary {
  background-image: radial-gradient(circle 590px at 8.2% 13.8%, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 90%);
    color: #fff;
    transition: all .5s ease;
}

.button-secondary:hover {
  background-image: radial-gradient(circle 590px at 8.2% 13.8%, #ccc 0%, #fff 90%);
    color: #000;
}

.wallet-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .7);
    z-index: 9;
    cursor: pointer;
}

.wallet-popup {
    position: fixed;
    top: 5vh;
    left: 5vw;
    width: 90vw;
    height: 90vh;
    overflow: scroll;
    z-index: 10;
    background-color: #000;
    border-radius: 20px;
    border: 1px solid #fff;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wallet-popup-item {
    margin-top: 10px;
    & a {
        color: #009ecc;
        text-decoration: none;
    }
}

@keyframes loadingGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.loading-box {
  width: 50%;
  height: 48px;
  background: linear-gradient(270deg, #000, #808080, #000);
  background-size: 200% 200%;
  animation: loadingGradient 2s infinite;
  border-radius: 4px;
    margin-bottom: 20px;
}

.burger-menu {
  display: none;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
    z-index: 6;
}

.burger-menu .bar {
  background-color: #fff;
  height: 2px;
  width: 25px;
}

.nav-list-mobile {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
}

.nav-list-mobile a {
    color: #009ecc;
    text-decoration: none;
    font-weight: 600;
    font-size: 24px;
}

.cross {
    font-size: 30px;
    color: #fff;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

    background-color: rgb(0, 0, 0);
    opacity: 0;
    visibility: hidden;
    z-index: 3;
    transition: opacity .1s linear;
}

@media (max-width: 620px) {
    .desktop-nav {
        display: none !important;
    }
  .burger-menu {
    display: flex;
  }

  .nav-list {
    position: fixed;
    top: -100%;
    right: 0;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: 100%;
    background-color: #333;
    padding-top: 50vh;
    justify-content: flex-start;
    transform: translateY(-100%);
      transition: transform 0.3s ease-in-out;
  }

  .nav-list.show {
    transform: translateY(0);
  }

  .nav-list li {
    text-align: center;
  }

  .overlay {
      transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;
  }

  .overlay.show {
      opacity: 1;
      visibility: visible;
  }

    .nav-list-mobile.show {
        display: flex;
        flex-direction: column;
        z-index: 5;
    }

    .nav-list-mobile li {
        margin: 20px 0;
    }

    .nav-list-mobile .nav-list-link {
        text-decoration: none;
        font-size: 1.5rem;
    }

    .nav-list-mobile .button {
        padding: 10px 20px;
        font-weight: 600;
    }

    .nav-list-mobile li {
        opacity: 0;
        transform: translateY(30px);
        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }

    .nav-list-mobile.show li {
        opacity: 1;
        transform: translateY(0);
    }
}